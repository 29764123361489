<template>
  <div ref="wrapperRef" class="w-full h-full relative backdrop-blur-sm overflow-hidden border-none bg-transparent">
    <template v-if="loading">
      <div class="w-full h-[20vh] md:h-full bg-transparent skeleton rounded-md"></div>
    </template>
    <template v-else>
      <div class="oxbcalendar md:block hidden w-full sticky top-0">
        <VCalendar ref="calendarRef" :columns="1" expanded transparent borderless :attributes="$calendar?.attributes" :is-dark="true">
          <template #day-popover="{ date, dayTitle, attributes }">
            <div class="px-2">
              <div class="text-xs text-gray-700 dark:text-gray-300 font-semibold text-center">
                {{ dayTitle }}
              </div>
              <ul class="divide-y divide-white/20">
                <li v-for="{ key, customData } in attributes" :key="key" class="block text-base p-2">
                  <div class="w-[250px] grid grid-cols-[32px_1fr] justify-center items-center gap-2">
                    <div class="w-[32px] h-[32px] rounded-lg ring ring-woodsmoke-400 p-0.5">
                      <NuxtImg loading="lazy" width="30" height="30" quality="30" class="rounded-md" :src="customData.project_icon" :alt="customData.title" />
                    </div>
                    <div>
                      <p class="text-sm font-light">{{ customData.title }}</p>
                      <p class="text-sm font-bold underline">{{ customData.event_title }}</p>
                    </div>

                    <div class="col-span-full" v-if="customData?.from_only">
                      <div class="text-sm mb-0.5 text-woodsmoke-100 inline-flex items-center justify-start">
                        <div class="p-0.5 px-1 bg-woodsmoke-600 text-white rounded-md">starts</div>
                        &nbsp;{{ formatDate(customData?.start, customData?.from_format) }}
                      </div>
                    </div>
                    <div v-else class="col-span-full">
                      <div class="text-sm mb-0.5 text-woodsmoke-100 inline-flex items-center justify-start">
                        <div class="p-0.5 px-1 bg-woodsmoke-600 text-white rounded-md">starts</div>
                        &nbsp;{{ formatDate(customData?.start, customData?.from_format) }}
                      </div>

                      <div class="text-sm text-woodsmoke-100 inline-flex items-center justify-start">
                        <div class="p-0.5 px-1 bg-woodsmoke-600 text-white rounded-md">ends</div>
                        &nbsp;{{ formatDate(customData?.end, customData?.to_format) }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </VCalendar>

        <div class="w-full flex justify-between items-center px-4 pb-4 border-b border-b-white/10">
            <p class="text-white">Upcoming Events</p>
            <NuxtLink to="/app/calendar">
              <button class="btn btn-sm border border-white/5 bg-white/5 backdrop-blur-sm">Open Calendar</button>
            </NuxtLink>
          </div>
      </div>
      <div class="md:block hidden w-full h-full max-h-full p-4 space-y-4 mb-[50vh]">
        <div v-for="_event in $calendar?.upcomingEvents" class="p-4 bg-white/5 rounded-md border border-white/10 grid grid-cols-6 gap-4">
                <LazyHomeCalendarEvent :event="_event" @gotoDate="gotoDate" ></LazyHomeCalendarEvent>
              </div>
      </div>

      <div class="md:p-4 w-full flex justify-between items-center pb-4 px-4 pt-4 md:hidden">
          <div class="w-full md:w-auto flex justify-between md:justify-start items-center space-x-2">
            <p class="text-white md:pl-0">Upcoming Events</p>
            <NuxtLink to="/app/calendar">
              <button class="btn btn-sm border border-white/5 bg-white/5 backdrop-blur-sm">Open Calendar</button>
            </NuxtLink>
          </div>

        </div>
      
      <div class="w-full h-full md:hidden flex md:justify-start justify-center items-center">
          <Splide ref="splideEl" class="h-full md:w-auto w-full" :options="{ arrows: false, autoWidth: true, padding: 0, gap: 6, speed: 1000, pagination: false }">
            <SplideSlide class="first:md:pl-4 first:pl-4 last:pr-4" style="border: 0" v-for="_event in $calendar?.upcomingEvents">
              <div class="p-4 bg-white/5 rounded-md border border-white/10 grid grid-cols-6 gap-4">
                <div class="flex justify-start items-center space-x-2 col-span-full">
                  <div class="w-10 h-10 bg-white/10 border border-white/10 p-1 rounded-md">
                    <NuxtImg loading="lazy" quality="50" width="50" height="50" class="w-full h-full object-contain rounded-md" :src="_event.project_icon" :alt="_event.project_name" />
                  </div>
                  <div>
                    <p class="text-sm">{{ _event.project_name }}</p>
                    <p class="text-white">{{ _event.title }}</p>
                  </div>
                </div>

                <div class="col-span-3">
                  <p class="text-xs text-woodsmoke-200">From</p>
                  <button @click="gotoDate(_event?.from_unix)" class="text-woodsmoke-50 text-sm underline hover:text-turquoise-500">{{ formatDate(_event?.from_unix * 1000, _event?.from_format) }}</button>
                </div>

                <div v-if="!_event?.from_only" class="col-span-3">
                  <p class="text-xs text-woodsmoke-200">To</p>
                  <p  @click="gotoDate(_event?.to_unix)" class="text-woodsmoke-50 text-sm underline hover:text-turquoise-500">{{ formatDate(_event?.to_unix * 1000, _event?.to_format) }}</p>
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      <!--<div class="md:col-span-3 w-full h-full flex flex-col justify-start items-start pb-4">
        <div class="md:p-4 w-full flex justify-between items-center pb-2">
          <div class="w-full md:w-auto flex justify-between md:justify-start items-center space-x-2">
            <p class="text-white md:pl-0 pl-8">Upcoming Events</p>
            <NuxtLink to="/app/calendar">
              <button class="btn btn-sm mr-8 border border-white/5 bg-white/5 backdrop-blur-sm">Open Calendar</button>
            </NuxtLink>
          </div>

          <div class="hidden md:block">
            <button @click="splideEl.go('<')" data-blobity-magnetic="false" class="group-hover:bg-turquoise-500/20 border-none group-hover:text-turquoise-500 group-hover:scale-110 btn p-0 m-0 mr-4 btn-sm bg-white/5 rounded-full h-8 w-8">
              <Icon class="text-lg" name="material-symbols:chevron-left"></Icon>
            </button>
            <button @click="splideEl.go('>')" data-blobity-magnetic="false" class="group-hover:bg-turquoise-500/20 border-none group-hover:text-turquoise-500 group-hover:scale-110 btn p-0 m-0 btn-sm bg-white/5 rounded-full h-8 w-8">
              <Icon class="text-lg" name="material-symbols:chevron-right"></Icon>
            </button>
          </div>
        </div>

        <div class="w-full h-full flex md:justify-start justify-center items-center">
          <Splide ref="splideEl" class="h-full md:w-auto w-full" :options="{ arrows: false, autoWidth: true, padding: 0, gap: 6, speed: 1000, pagination: false }">
            <SplideSlide class="first:md:pl-4 first:pl-8 last:pr-4" style="border: 0" v-for="_event in upcomingEvents">
              <div class="p-4 bg-white/5 rounded-md border border-white/10 grid grid-cols-6 gap-4">
                <div class="flex justify-start items-center space-x-2 col-span-full">
                  <div class="w-10 h-10 bg-white/10 border border-white/10 p-1 rounded-md">
                    <img class="w-full h-full object-contain rounded-md" :src="_event.project_icon" />
                  </div>
                  <div>
                    <p class="text-sm">{{ _event.project_name }}</p>
                    <p class="text-white">{{ _event.title }}</p>
                  </div>
                </div>

                <div class="col-span-3">
                  <p class="text-xs text-woodsmoke-200">From</p>
                  <button @click="gotoDate(_event?.from_unix)" class="text-woodsmoke-50 text-sm underline hover:text-turquoise-500">{{ formatDate(_event?.from_unix * 1000, _event?.from_format) }}</button>
                </div>

                <div v-if="!_event?.from_only" class="col-span-3">
                  <p class="text-xs text-woodsmoke-200">To</p>
                  <p  @click="gotoDate(_event?.to_unix)" class="text-woodsmoke-50 text-sm underline hover:text-turquoise-500">{{ formatDate(_event?.to_unix * 1000, _event?.to_format) }}</p>
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>

        <!~~ <div v-for="_event in upcomingEvents" class="p-4 border-b border-b-white/5 grid grid-cols-6">
          <div class="flex justify-start items-center space-x-2 col-span-2">
            <div class="w-10 h-10 bg-white/10 border border-white/10 p-1 rounded-md">
              <img class="w-full h-full object-contain rounded-md" :src="_event.project_icon" />
            </div>
            <div>
              <p class="text-sm">{{ _event.project_name }}</p>
              <p class="text-white">{{ _event.title }}</p>
            </div>
          </div>

          <div class="col-span-2">
            <p class="text-xs text-woodsmoke-200">From</p>
            <p class="text-woodsmoke-50">{{ getUtcDate(_event.from_unix * 1000) }}</p>
          </div>

          <div class="col-span-2">
            <p class="text-xs text-woodsmoke-200">To</p>
            <p class="text-woodsmoke-50">{{ getUtcDate(_event.to_unix * 1000) }}</p>
          </div>
        </div>  ~~>
      </div>-->

      <!--<div class="w-full h-full">
      <div class="w-full border-b border-b-white/5">
        <div class="w-full flex justify-between items-center p-2 border-b border-b-white/5 sticky">
          <p class="text-lg text-turquoise-500 pl-2">Calendar</p>

          <button class="btn bg-white/10 border-white/5 btn-sm">Open Calendar</button>
        </div>

        <!~~<VCalendar :columns="1" expanded transparent borderless :attributes="attributes" :is-dark="true">
          <template #day-popover="{ date, dayTitle, attributes }">
            <div class="px-2">
              <div class="text-xs text-gray-700 dark:text-gray-300 font-semibold text-center">
                {{ dayTitle }}
              </div>
              <ul class="divide-y divide-white/20">
                <li v-for="{ key, customData } in attributes" :key="key" class="block text-base p-2">
                  <div class="w-[250px] grid grid-cols-[32px_1fr] justify-center items-center gap-2">
                    <div class="w-[32px] h-[32px] rounded-lg ring ring-woodsmoke-400 p-0.5">
                      <img class="rounded-md" :src="customData.project_icon" alt="" />
                    </div>
                    <div>
                      <p class="text-sm font-light">{{ customData.title }}</p>
                      <p class="text-sm font-bold underline">{{ customData.event_title }}</p>
                      <!~~ {{ customData }} ~~>
                    </div>

                    <div class="col-span-full">
                      <div class="text-sm mb-0.5 text-woodsmoke-100 inline-flex items-center justify-start">
                        <div class="p-0.5 px-1 bg-woodsmoke-600 text-white rounded-md">starts</div>
                        &nbsp;{{ getUtcDate(customData.start) }}
                      </div>

                      <div class="text-sm text-woodsmoke-100 inline-flex items-center justify-start">
                        <div class="p-0.5 px-1 bg-woodsmoke-600 text-white rounded-md">ends</div>
                        &nbsp;{{ getUtcDate(customData.end) }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </VCalendar>~~>
      </div>

      <div class="w-full h-full">
        <div class="px-4 pt-4">
          <p class="text-white underline">Upcoming Events</p>
        </div>
        <!~~ <div v-for="_event in upcomingEvents" class="p-4 border-b border-b-white/5 grid grid-cols-6">
          <div class="flex justify-start items-center space-x-2 col-span-2">
            <div class="w-10 h-10 bg-white/10 border border-white/10 p-1 rounded-md">
              <img class="w-full h-full object-contain rounded-md" :src="_event.project_icon" />
            </div>
            <div>
              <p class="text-sm">{{ _event.project_name }}</p>
              <p class="text-white">{{ _event.title }}</p>
            </div>
          </div>

          <div class="col-span-2">
            <p class="text-xs text-woodsmoke-200">From</p>
            <p class="text-woodsmoke-50">{{ getUtcDate(_event.from_unix * 1000) }}</p>
          </div>

          <div class="col-span-2">
            <p class="text-xs text-woodsmoke-200">To</p>
            <p class="text-woodsmoke-50">{{ getUtcDate(_event.to_unix * 1000) }}</p>
          </div>
        </div> ~~>
      </div>
    </div>-->

      <!--<ClientOnly>
        <Watermark class="z-10 pointer-events-none top-0 left-0" v-if="$address" :watertext="$address"></Watermark>
      </ClientOnly>-->
    </template>
  </div>
</template>

<script setup>
import { useUserStore } from "~/stores/user.js";
const userStore = useUserStore();

// import { config } from "~/wagmiConfig.js";
// import { getAccount, watchAccount, watchConnections } from "@wagmi/core";
import { useElementSize } from "@vueuse/core";


const wrapperRef = ref(null);
const calendarParentRef = ref(null);

const { height:wrapperHeight } = useElementSize(wrapperRef);
const { height:calendarParentHeight } = useElementSize(calendarParentRef);

// const eventHeight = computed(() => {
//   return wrapperHeight.value - calendarParentHeight.value;
// });


// import { queryNoCache } from "~/apollo";
// import { gql } from "@apollo/client/core";

// import { parseISO } from "date-fns";
// import { format, utcToZonedTime } from "date-fns-tz";

import { formatDate } from "~/module/calendar.js";

// const address = ref("");
const loading = ref(false);

const splideEl = ref(null);

defineExpose({
  loading,
});

import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

const { $address, $accessToken, $calendar, $userData, $renderCalendar } = useNuxtApp()


// const unwatch = watchConnections(config, {
//   onChange(data) {
//     if (data) {
//       let _account = getAccount(config);
//       address.value = _account.address;
//     } else {
//       address.value = null;
//     }
//     renderCalendar();
//   },
// });

// const unwatchAccount = watchAccount(config, {
//   onChange(data) {
//     renderCalendar();
//   },
// });

// const unsub = userStore.$subscribe((state) => {
//   renderCalendar();
// });

// onUnmounted(() => {
//   unwatch();
//   unsub();
//   unwatchAccount();
// });

onMounted(() => {
  $renderCalendar();

  watch( $userData, () => {
    $renderCalendar()
  })

  watch( $accessToken, () => {
    $renderCalendar()
  })

  watch( $address, () => {
    $renderCalendar()
  })

  // address.value = getAccount(config).address;
});

const calendarRef = ref(null);

watch(calendarRef, () => {
  if (calendarRef.value) {
    // today
    let today = new Date();
    calendarRef.value.focusDate(today);
  }
});
////

// import { createClient } from "@supabase/supabase-js";
// const supabase = createClient("https://ukfztwboozjkcxaclima.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrZnp0d2Jvb3pqa2N4YWNsaW1hIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODM2MDg0ODcsImV4cCI6MTk5OTE4NDQ4N30.15MQeuuQKzj-i4BgcMIDY30_pcx4qqw0feulCQREwIE");

import { useScreens } from "vue-screen-utils";

// const accountData = ref({});
// const attributes = ref([]); // calendar dates are stored here
const initialized = ref(true); // this is to show the loading spinner
// const projects = ref([]); // this is the list of projects
const showPnCModal = ref(false); // Private and Confidential modal flag
const isPermitted = ref(false);

const upcomingEvents = ref([]);

/// Just a simple list to store theses confidential text
let confidentialList = [];
for (let i = 0; i < 5; i++) {
  confidentialList.push("confidential");
}

const { mapCurrent } = useScreens({
  xs: "0px",
  sm: "640px",
  md: "768px",
  md: "1024px",
});
// const columns = mapCurrent({
//   xs: 1,
//   md: 2,
//   md: 2,
//   md: 2,
// });

// update callback from the calendar module
// const update = (args) => {
//   // console.log(args);
//   let { projects: _projects, isPermitted: _isPermitted, attributes: _attributes, upcomingEvents: _upcomingEvents } = args;
//   projects.value = _projects;
//   isPermitted.value = _isPermitted;
//   attributes.value = _attributes;
//   upcomingEvents.value = _upcomingEvents;

//   if (!initialized.value) {
//     initialized.value = true;
//   }

//   if (loading.value) {
//     loading.value = false;
//   }

//   let calendar_warning = localStorage.getItem("calendar_warning_2");
//   let now_plus_60 = new Date().getTime() + 60 * 60 * 1000;

//   if (!calendar_warning) {
//     showPnCModal.value = true;
//     localStorage.setItem("calendar_warning_2", now_plus_60);
//   } else {
//     /// check if current time is greater than the stored time

//     if (new Date().getTime() > calendar_warning) {
//       showPnCModal.value = true;
//       localStorage.setItem("calendar_warning_2", now_plus_60);
//     }
//   }

//   if (calendarRef.value) {
//     let today = new Date();
//     calendarRef.value.focusDate(today);
//   }
// };

// authState callback for the calendar module, to get current user's address and authentication status
// const authState = () => {
//   return {
//     authenticated: $accessToken.value ? true : false,
//     address: $address.value,
//   };
// };

// const _calendar = new Calendar({
//   update: update,
//   authState: authState,
// });

// const renderCalendar = () => {
//   _calendar.render();
// };

const gotoDate = (_unix) => {
  if (calendarRef.value) {
    let _date = new Date(_unix * 1000);
    calendarRef.value.focusDate(_date);
  }
};
</script>

<style>

</style>
